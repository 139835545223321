
.warscroll {
  background: antiquewhite;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  width:80%;
  flex-direction: column;
}

.top {
  display: flex;
  margin: 5px;
  align-items:center;
}

.weapons {
  padding: 5px;
}

.select {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.selectGroup {
  display: flex;
  flex-direction: column;
  margin: 0.5vw;
  width: 50%;

  .banner {
    height: 2.5vw;
  }
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
  background: #282c34;
  color: aliceblue;
  margin: 5px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  text-transform: uppercase;
  width: 10vw;
  min-width: 10vw;
  height: 100%;
}

.main {
  margin: 5px;
  padding: 10px;
}

.right {
  width: 100%;
}

.abilities {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  .ability{
    width:30%;
  }
}
.justAbilities{
  width:90%;
}

.abilityList {
  margin: 15px;
  display: flex;
  flex-wrap: wrap;
  width:100%;
  .ability{
    width:30%;
  }
}

.selectTop {
  flex-grow: 1;
}

.abilityBanner {
  margin: 5px;
  padding: 5px;
  display: flex;
  color: aliceblue;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 20%;

  h1 {
    margin: 0;
  }
}

.heroPhase {
  background: #b2a04f;
}

.movementPhase {
  background: #8e8e8e;
}

.defensive {
  background: #3d5218;
}

.combatPhase {
  background: #882024;
}

.startOfTurn {
  background: #000000;

}

.endOfTurn {
  background: #6d4882;
}

.chargePhase {
  background: #bf7731;
}

.shootingPhase {
  background: #225c6f;
}


.banner {
  margin: 5px;
  display: flex;
  background: #282c34;
  color: aliceblue;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 100%;

  h1 {
    margin: 0;
  }
}

.table {
  text-align: center;
  border: 0 solid;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 5px;

  caption {
    background: #282c34;
    color: aliceblue;
    font-weight: bold;
    text-transform: uppercase;
  }

  tr:hover {
    background-color: #0aa6e7;
  }

  thead {
    background: #2c346d;
    color: aliceblue;
  }

  tbody {
    background: #bbcad3;
    color: #042841;
  }
}

.listContainer {
  button {
    margin-bottom: 10px;
  }
}

.list {
  display: flex;
  flex-direction: column;

  button {
    margin: 10px;
  }
}

.listEntry {
}

.keywordBanner {
  display: flex;
  flex-direction: row;

  .words {
    font-weight: bold;
  }

}

.keywords {
  background: white;
  display: flex;
  flex-direction: row;
  border: 1px #2c346d solid;
  padding: 5px;

  .words {
    font-weight: bold;
  }

  strong {
    padding: 5px;
    background: #2c346d;
    color: white;
  }
}